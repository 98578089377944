.card {
  position: relative;
}

.main {
  display: flex;
  padding-top: 0.3rem;
  padding-bottom: 1.1rem;
}

.main .left {
  width: 9.5rem;
}

.main .left .title {
  padding-bottom: 0.2rem;
}

.main .left .title h1 {
  display: flex;
  margin-bottom: 0.15rem;
  align-items: flex-start;
  position: relative;
  padding-right: 0.2rem;
}

.main .left .title h1 span {
  font-size: 0.2rem;
  color: #333333;
  font-weight: bold;
  line-height: 0.3rem;
}

.main .left .title .car_type {
  padding: 0 0.1rem;
  border-radius: 0.03rem;
  color: #fff;
  background-color: #ff0000;
  font-size: 0.16rem;
  line-height: 0.3rem;
  margin-right: 0.1rem;
  flex-shrink: 0;
}

.main .left .title .info_box {
  display: flex;
  margin-bottom: 0.1rem;
  line-height: 0.3rem;
}

.main .left .title .info_box .label {
  min-width: 0.88rem;
  font-size: 0.18rem;
  color: #000000;
  line-height: 0.3rem;
  font-weight: bold;
}

.main .left .title .source {
  font-size: 0.18rem;
  line-height: 0.3rem;
  color: #555555;
}

.main .left .title .progress > div {
  display: flex;
  align-items: center;
}

.main .left .title .progress > div span {
  flex-shrink: 0;
  margin-left: 0.1rem;
  font-size: 0.16rem;
  color: #888888;
}

.main .left .content {
  background-color: #f5f5f5;
  position: relative;
}

.main .left .content .info {
  color: #000;
  height: 6.6rem;
  position: relative;
}

.main .left .content .info::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #d5d5d5;
}

.main .left .content .info::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #aaaaaa;
}

.main .left .content .info .left-pop {
  position: absolute;
  width: 3rem;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  background-color: #eee;
  z-index: 2;
  padding: 0.2rem 0 0.2rem 0.2rem;
  transition: 0.3s;
}

.main .left .content .info .left-pop::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #d5d5d5;
}

.main .left .content .info .left-pop::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #aaaaaa;
}

.main .left .content .info .left-pop.active {
  transform: translateX(0);
}

.main .left .content :deep(.editor) {
  line-height: 22pt;
  background-color: #eee;
}

.main .left .content :deep(.editor) [id*='test'] {
  height: 6.6rem;
  overflow-y: auto;
}

.main .left .content :deep(.editor) [id*='test']::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #d5d5d5;
}

.main .left .content :deep(.editor) [id*='test']::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #aaaaaa;
}

.main .left .content .btn_box {
  position: absolute;
  right: 100%;
  padding-right: 0.1rem;
  top: 0;
}

.main .left .content .btn_box .item {
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  font-size: 0.17rem;
  color: #000;
  border-radius: 0.03rem;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 1.2;
  padding: 0 0.1rem;
  margin-bottom: 0.01rem;
  transition: 0.3s;
  cursor: pointer;
  font-weight: bold;
}

.main .left .content .btn_box .item.active,
.main .left .content .btn_box .item:hover {
  background-color: #00ffc0;
  color: #000;
}

.main .left :deep(.ant-empty) {
  margin-top: 1rem;
}

.main .right {
  width: 4.9rem;
  padding-left: 0.3rem;
}

.collect {
  color: #999;
  position: absolute;
  right: 0;
  top: 0.1rem;
  cursor: pointer;
}

.collect.active {
  color: #f6a75c;
}

/* fade-transform */

.list-leave-active,
.list-enter-active {
  transition: all 0.5s;
}

.list-enter {
  opacity: 0;
}

.list-leave-to {
  opacity: 0;
}

.goback {
  position: absolute;
  left: 0.5rem;
  top: 0.2rem;
}