.level {
  display: flex;
  align-items: center;
}

.level span {
  flex-shrink: 0;
  margin-left: 0.1rem;
  font-size: 0.16rem;
  color: #888888;
}