/deep/ .h2 {
  padding-bottom: 0 !important;
  justify-content: space-between;
}

.item {
  display: flex;
  padding: 0.2rem 0;
  justify-content: space-between;
  border-bottom: 0.01rem solid #e5e5e5;
  flex: 1;
}

.item .left {
  width: 0;
  flex: 1;
}

.item .svg-icon {
  flex-shrink: 0;
  font-size: 0.16rem;
  color: #bbb;
  cursor: pointer;
}

.item h2 {
  line-height: 1;
  font-size: 0.17rem;
  color: #333333;
  padding-left: 0.22rem;
  position: relative;
  margin-bottom: 0.05rem;
  cursor: pointer;
}

.item h2::before {
  content: '';
  width: 0.05rem;
  height: 0.05rem;
  background-color: #f49133;
  position: absolute;
  left: 0;
  top: 0.06rem;
}

.item h2::after {
  content: '';
  width: 0;
  height: 0;
  border: 0.025rem solid transparent;
  border-left-color: #f49133;
  position: absolute;
  left: 0.05rem;
  top: 0.06rem;
}

.item .des {
  padding-left: 0.22rem;
  font-size: 0.16rem;
  color: #555555;
  line-height: 2;
  height: 3em;
  cursor: pointer;
}

.item .source {
  padding-left: 0.22rem;
  line-height: 1;
  font-size: 0.16rem;
  margin-top: 0.15rem;
}

.item:hover h2 {
  color: #f49133;
}

.item:hover .des {
  color: #f49133;
}

.item:hover .svg-icon {
  color: #f49133;
}

/deep/ .ant-list-item {
  border-bottom: 0 !important;
  flex: 1;
  width: 100%;
  padding: 0 !important;
}

/deep/ .ant-list-pagination {
  position: absolute;
  right: 0;
  top: -0.32rem;
  margin: 0;
}

/deep/ .ant-pagination-simple-pager {
  display: none !important;
}

/deep/ .ant-pagination-prev,
/deep/ .ant-pagination-next {
  width: 0.3rem;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
  margin-left: 0.1rem;
  margin-right: 0 !important;
}